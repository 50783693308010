<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_package')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-row>
                        <v-col
                                cols="12"
                                md="6"
                        >
                            <v-text-field v-model="editedData.packageName"
                                          :label="$t('package_name')"
                                          required
                                          dense
                                          outlined
                                          :error-messages="packageNameErrors"
                                          @input="$v.editedData.packageName.$touch()"
                                          @blur="$v.editedData.packageName.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="6"
                        >
                            <v-text-field v-model="editedData.packageType"
                                          :label="$t('type')"
                                          required
                                          dense
                                          outlined
                                          :error-messages="packageTypeErrors"
                                          @input="$v.editedData.packageType.$touch()"
                                          @blur="$v.editedData.packageType.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="12"
                        >
                            <v-text-field v-model.number="editedData.packageRate"
                                          type="number"
                                          :label="$t('rate')"
                                          required
                                          dense
                                          outlined
                                          :error-messages="packageRateErrors"
                                          @input="$v.editedData.packageRate.$touch()"
                                          @blur="$v.editedData.packageRate.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                        >
                            <v-textarea
                                    clearable
                                    clear-icon="cancel"
                                    v-model="editedData.packageDescription"
                                    :label="$t('description')"
                                    required
                                        dense
                                          outlined
                                    :error-messages="packageDescriptionErrors"
                                    @input="$v.editedData.packageDescription.$touch()"
                                    @blur="$v.editedData.packageDescription.$touch()"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success", 'packageID'],
        computed: {
            packageNameErrors() {
                const errors = [];
                if (!this.$v.editedData.packageName.$dirty) return errors;
                !this.$v.editedData.packageName.required &&
                errors.push("Name is required");
                return errors;
            },
            packageTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.packageType.$dirty) return errors;
                !this.$v.editedData.packageType.required &&
                errors.push("Type is required");
                return errors;
            },
            packageDescriptionErrors() {
                const errors = [];
                if (!this.$v.editedData.packageName.$dirty) return errors;
                !this.$v.editedData.packageName.required &&
                errors.push("Description is required");
                return errors;
            },
            packageRateErrors() {
                const errors = [];
                if (!this.$v.editedData.packageRate.$dirty) return errors;
                !this.$v.editedData.packageRate.required &&
                errors.push("Rate is required");
                return errors;
            }
        },

        data() {
            return {
                selectData: {
                    packages: [],
                    selectedValue: null
                },
                province: [],
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                packageName: {required},
                packageType: {required},
                packageRate: {required},
                packageDescription: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            formData() {
                this.updated = false
                axios.get(`Trekking/GetTrekkingPackageByIDAsync/${this.packageID}`).then(response => {
                    this.editedData = response.data
                })
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("Trekking/UpdateTrekkingPackageAsync", this.editedData).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Package updated succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"

                    });
                    this.submitStatus = "PENDING";
                    setTimeout(() => {
                        this.submitStatus = "OK";
                    }, 500);
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.from = "";
                this.editedData.to = "";
            }
        }
    };
</script>

<style scoped>
</style>